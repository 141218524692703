import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "input-group mb-3 justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives((_openBlock(), _createElementBlock("button", {
      type: "button",
      class: _normalizeClass(["btn", _ctx.hasIppon ? 'btn-secondary' : 'btn-outline-secondary']),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClick(_ctx.hasIppon ? '00' : '10')))
    }, [
      _createTextVNode(_toDisplayString(_ctx.$t("ippon")), 1)
    ], 2)), [
      [_directive_tooltip, _ctx.$t('button-tooltip.set-competition-result-ippon'), "bottom"]
    ]),
    _withDirectives((_openBlock(), _createElementBlock("button", {
      type: "button",
      class: _normalizeClass(["btn", _ctx.hasWazaAri ? 'btn-secondary' : 'btn-outline-secondary']),
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onClick(_ctx.hasWazaAri ? '00' : '07')))
    }, [
      _createTextVNode(_toDisplayString(_ctx.$t("waza-ari")), 1)
    ], 2)), [
      [_directive_tooltip, _ctx.$t('button-tooltip.set-competition-result-waza-ari'), "bottom"]
    ]),
    _withDirectives((_openBlock(), _createElementBlock("button", {
      type: "button",
      class: _normalizeClass(["btn", _ctx.hasYuko ? 'btn-secondary' : 'btn-outline-secondary']),
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onClick(_ctx.hasYuko ? '00' : '01')))
    }, [
      _createTextVNode(_toDisplayString(_ctx.$t("yuko")), 1)
    ], 2)), [
      [_directive_tooltip, _ctx.$t('button-tooltip.set-competition-result-yuko'), "bottom"]
    ])
  ]))
}