import { ValueHelpApi } from "@/api/ValueHelp.api";
import { ValueHelpQuery } from "@/types/ValueHelpQuery.enum";
import { ValueHelpResponse } from "@/types/ValueHelpResponse";
import { computed, ComputedRef, reactive } from "vue";
import { ValueHelpState } from "./ValueHelp.state";

const _valueHelpApi = new ValueHelpApi();
const _valueHelpState = reactive({
  weightClasses: [],
  ageClasses: [],
  executionModes: [],
  obi: [],
} as ValueHelpState);

export function useValueHelpState() {
  return {
    valueHelpState: computed(() => _valueHelpState),
  };
}

export function useValueHelpStore(code: ValueHelpQuery): ValueHelpStore {
  let store: ValueHelpStore;

  switch (code) {
    case ValueHelpQuery.WeightClasses:
      store = WeightClassStore.instance;
      break;
    case ValueHelpQuery.AgeClasses:
      store = AgeClassStore.instance;
      break;
    case ValueHelpQuery.ExecutionModes:
      store = ExecutionModeStore.instance;
      break;
    case ValueHelpQuery.ObiList:
      store = ObiStore.instance;
      break;
    default:
      throw new Error("Method not implemented.");
  }

  return store;
}

interface ValueHelpStore {
  loadList(reload: boolean): Promise<void>;
  items: ComputedRef<ValueHelpResponse[]>;
}

class WeightClassStore implements ValueHelpStore {
  protected static _instance: WeightClassStore;

  static get instance() {
    if (!this._instance) this._instance = new WeightClassStore();
    return this._instance;
  }

  async loadList(reload: boolean): Promise<void> {
    // const { addProcess, removeProcess } = useLoadingStore();
    // addProcess(ProcessNames.LOAD_WEIGHT_CLASSES);
    try {
      if (reload || _valueHelpState.weightClasses?.length === 0)
        _valueHelpState.weightClasses = await _valueHelpApi.loadValueHelp(
          ValueHelpQuery.WeightClasses
        );
    } catch (error) {
      console.error(error);
    } finally {
      // removeProcess(ProcessNames.LOAD_WEIGHT_CLASSES);
    }
  }
  items = computed(() => _valueHelpState.weightClasses);
}

class AgeClassStore implements ValueHelpStore {
  protected static _instance: AgeClassStore;

  static get instance() {
    if (!this._instance) this._instance = new AgeClassStore();
    return this._instance;
  }
  async loadList(reload: boolean): Promise<void> {
    // const { addProcess, removeProcess } = useLoadingStore();
    // addProcess(ProcessNames.LOAD_AGE_CLASSES);
    try {
      if (reload || _valueHelpState.ageClasses?.length === 0)
        _valueHelpState.ageClasses = await _valueHelpApi.loadValueHelp(
          ValueHelpQuery.AgeClasses
        );
    } catch (error) {
      console.error(error);
    } finally {
      // removeProcess(ProcessNames.LOAD_AGE_CLASSES);
    }
  }
  items = computed(() => _valueHelpState.ageClasses);
}

class ExecutionModeStore implements ValueHelpStore {
  protected static _instance: ExecutionModeStore;

  static get instance() {
    if (!this._instance) this._instance = new ExecutionModeStore();
    return this._instance;
  }
  async loadList(reload: boolean): Promise<void> {
    // const { addProcess, removeProcess } = useLoadingStore();
    // addProcess(ProcessNames.LOAD_EXECUTION_MODES);
    try {
      if (reload || _valueHelpState.executionModes?.length === 0)
        _valueHelpState.executionModes = await _valueHelpApi.loadValueHelp(
          ValueHelpQuery.ExecutionModes
        );
    } catch (error) {
      console.error(error);
    } finally {
      // removeProcess(ProcessNames.LOAD_EXECUTION_MODES);
    }
  }
  items = computed(() => _valueHelpState.executionModes);
}

class ObiStore implements ValueHelpStore {
  protected static _instance: ObiStore;

  static get instance() {
    if (!this._instance) this._instance = new ObiStore();
    return this._instance;
  }
  async loadList(reload: boolean): Promise<void> {
    // const { addProcess, removeProcess } = useLoadingStore();
    // addProcess(ProcessNames.LOAD_EXECUTION_MODES);
    try {
      if (reload || _valueHelpState.obi?.length === 0)
        _valueHelpState.obi = await _valueHelpApi.loadValueHelp(
          ValueHelpQuery.ObiList
        );
    } catch (error) {
      console.error(error);
    } finally {
      // removeProcess(ProcessNames.LOAD_EXECUTION_MODES);
    }
  }
  items = computed(() => _valueHelpState.obi);
}
