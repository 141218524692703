import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  class: "toast-container position-absolute p-3",
  style: {"right":"0px"}
}
const _hoisted_2 = { class: "toast-header" }
const _hoisted_3 = { class: "me-auto" }
const _hoisted_4 = { class: "text-muted" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "toast-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notifications, (notification) => {
      return (_openBlock(), _createElementBlock("div", {
        key: notification.id,
        class: "toast show",
        role: "alert",
        "aria-live": "assertive",
        "aria-atomic": "true"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: _normalizeClass(["toast-type rounded mr-2", {
            success: notification.type == 'success',
            warning: notification.type == 'warning',
            danger: notification.type == 'error',
          }])
          }, null, 2),
          _createElementVNode("strong", _hoisted_3, _toDisplayString(notification.title), 1),
          _createElementVNode("small", _hoisted_4, _toDisplayString(notification.tag), 1),
          _createElementVNode("button", {
            type: "button",
            class: "btn-close",
            "data-bs-dismiss": "toast",
            "aria-label": "Close",
            onClick: ($event: any) => (_ctx.remove(notification.id))
          }, null, 8, _hoisted_5)
        ]),
        _createElementVNode("div", _hoisted_6, _toDisplayString(notification.details), 1)
      ]))
    }), 128))
  ]))
}