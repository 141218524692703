
import { useNotificationStore } from "../../stores/Notification.store";
import { defineComponent } from "vue";

export default defineComponent({
  name: "notification",
  setup: () => {
    const { notifications, remove } = useNotificationStore();
    return { remove, notifications };
  },
});
