import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-47828856"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "print-container d-print-grid" }
const _hoisted_2 = { class: "fight-number" }
const _hoisted_3 = { class: "fight-number-value" }
const _hoisted_4 = { class: "athlete-a" }
const _hoisted_5 = { class: "athlete-a-country-iso-code-value" }
const _hoisted_6 = { class: "athlete-a-name-value" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 2 }
const _hoisted_10 = { class: "result" }
const _hoisted_11 = { class: "result-value" }
const _hoisted_12 = { class: "athlete-b" }
const _hoisted_13 = { class: "athlete-b-name-value" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { key: 1 }
const _hoisted_16 = { key: 2 }
const _hoisted_17 = { class: "athlete-b-country-iso-code-value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.competition?.fightNumber), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.competition?.athleteA?.countryCodeIOC), 1),
      _createElementVNode("div", _hoisted_6, [
        (_ctx.competition?.athleteAIsEmpty)
          ? (_openBlock(), _createElementBlock("span", _hoisted_7, " - "))
          : (_ctx.competition?.athleteB)
            ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.competition?.athleteA?.firstName) + " " + _toDisplayString(_ctx.competition?.athleteA?.lastName), 1))
            : (_openBlock(), _createElementBlock("span", _hoisted_9, " _______ "))
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.competition?.result?.athleteAPoints) + " : " + _toDisplayString(_ctx.competition?.result?.athleteBPoints), 1)
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("div", _hoisted_13, [
        (_ctx.competition?.athleteBIsEmpty)
          ? (_openBlock(), _createElementBlock("span", _hoisted_14, " - "))
          : (_ctx.competition?.athleteB)
            ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_ctx.competition?.athleteB?.firstName) + " " + _toDisplayString(_ctx.competition?.athleteB?.lastName), 1))
            : (_openBlock(), _createElementBlock("span", _hoisted_16, " _______ "))
      ]),
      _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.competition?.athleteB?.countryCodeIOC), 1)
    ])
  ]))
}