import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { errorRoutes } from "./error.routes";
import { privateBeforeEach, privateRoutes } from "./private.routes";
import { publicRoutes } from "./public.routes";
import { publicOnlyRoutes } from "./publicOnly.routes";

export const routes: Array<RouteRecordRaw> = [
  ...privateRoutes,
  ...publicOnlyRoutes,
  ...publicRoutes,
  ...errorRoutes,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(privateBeforeEach);

export default router;
