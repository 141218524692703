import { reactive, computed } from "vue";
import { Notification } from "../types/Notification";

const state = reactive({
  notifications: [] as Notification[],
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useNotificationStore = () => {
  const notifications = computed(() => state.notifications);

  const generateId = () => {
    return notifications.value.length + 1;
  };

  const remove = (id: number | undefined) => {
    state.notifications = state.notifications.filter(
      (notification) => notification.id != id
    );
  };

  const add = (_notification: Notification) => {
    _notification.id = generateId();
    state.notifications.push(_notification);

    setTimeout(() => {
      remove(_notification.id);
    }, _notification.duration || 3000);
  };

  return {
    add,
    remove,
    notifications,
  };
};
