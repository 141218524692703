import { Axios } from "./Axios";

export class championshipDashboardApi {
  getEndpoint(id: string): string {
    return `championships/${id}/dashboard`;
  }

  async checkLicense(championshipId: string): Promise<boolean> {
    return await Axios.instance.api
      .get<boolean>(`${this.getEndpoint(championshipId)}/check_license`)
      .then(({ data }) => {
        return data;
      });
  }

  async loadWeighedKPI(championshipId: string): Promise<any> {
    return await Axios.instance.api
      .get<any[]>(`${this.getEndpoint(championshipId)}/weighed_status`)
      .then(({ data }) => {
        return data;
      });
  }

  async loadFightTime(championshipId: string): Promise<any> {
    return await Axios.instance.api
      .get<any[]>(`${this.getEndpoint(championshipId)}/fight_time`)
      .then(({ data }) => {
        return data;
      });
  }

  async loadRegistrationNationKPI(championshipId: string): Promise<any> {
    return await Axios.instance.api
      .get<any[]>(`${this.getEndpoint(championshipId)}/nations`)
      .then(({ data }) => {
        return data;
      });
  }
  async loadNationRanking(championshipId: string): Promise<any> {
    return await Axios.instance.api
      .get<any[]>(`${this.getEndpoint(championshipId)}/nations_ranking`)
      .then(({ data }) => {
        return data;
      });
  }

  async loadListKPI(championshipId: string): Promise<any> {
    return await Axios.instance.api
      .get<any[]>(`${this.getEndpoint(championshipId)}/list`)
      .then(({ data }) => {
        return data;
      });
  }

  async loadNextFightPerCategory(championshipId: string): Promise<any> {
    return await Axios.instance.api
      .get<any[]>(
        `${this.getEndpoint(championshipId)}/next_competition_per_class`
      )
      .then(({ data }) => {
        return data;
      });
  }
}
