import axios, { AxiosInstance, AxiosResponse } from "axios";
import { AuthAPI } from "./Auth.api";

export class Axios {
  private static _instance: Axios;

  private _api: AxiosInstance;
  static readonly LOCAL_STORAGE_KEY: string =
    process.env.VUE_APP_LOCAL_STORAGE_KEY_AUTH;

  private constructor() {
    this._api = axios.create({ baseURL: process.env.VUE_APP_ROOT_API });
    this._initializeResponseInterceptor();
  }

  public static get instance(): Axios {
    if (!this._instance) {
      this._instance = new Axios();
    }
    return this._instance;
  }

  public get api(): AxiosInstance {
    const authHeader = this._authHeader();
    this._api.defaults.headers.common["Content-Type"] = "application/json";
    if (authHeader) {
      this._api.defaults.headers.common["Authorization"] = authHeader;
    } else {
      this._api.defaults.headers.common["Authorization"] = "";
    }
    return this._api;
  }

  private _initializeResponseInterceptor = () => {
    this._api.interceptors.response.use(
      this._handleResponse,
      this._handleError
    );
  };

  private _authHeader() {
    const storage = localStorage.getItem(Axios.LOCAL_STORAGE_KEY);
    if (!storage) return undefined;
    const user = JSON.parse(storage);
    if (user && user.access_token) {
      return "Bearer " + user.access_token;
    } else {
      return undefined;
    }
  }

  private _handleResponse = (response: AxiosResponse) => response;

  protected _handleError = async (error: any) => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const originalRequest = error.config;
      if (
        error.response.status === 401 &&
        !originalRequest._retry &&
        originalRequest.url != "auth/refresh_token"
      ) {
        originalRequest._retry = true;

        const authApi = new AuthAPI();
        const refreshToken = await authApi.getRefreshToken();
        if ((await authApi.appHasRefreshToken()) && refreshToken) {
          const { access_token } = await authApi.refreshToken(refreshToken);
          originalRequest.headers["Authorization"] = "Bearer " + access_token;
          return this.api(originalRequest);
        }
      }

      return Promise.reject(error.response);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      return Promise.reject(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      return Promise.reject(error);
    }
  };
}
