import { Tooltip } from "bootstrap";

export default {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  mounted(el: HTMLElement, binding: any) {
    new Tooltip(el, {
      placement: binding.arg || "top",
      title: binding.value || "",
      delay: { show: 500, hide: 0 },
      customClass: "d-print-none",
    });
  },
};
