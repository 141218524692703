
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    modelValue: {
      type: String,
      default: () => {
        "00:00";
      },
    },
  }, //"00:00"
  setup: (props, { emit }) => {
    const validateMinutes = (value: string) => {
      let minutes = value;
      if (value.indexOf(":") >= 0) {
        minutes = value.split(":")[0];
      }
      const minutesNew =
        Number(minutes) <= 0 || Number(minutes) >= 60
          ? "00"
          : Number(minutes) < 10
          ? "0" + Number(minutes)
          : Number(minutes);

      return minutesNew;
    };

    const validateSeconds = (value: string, onChange = false) => {
      if (value.indexOf(":") >= 0) {
        const seconds = value.split(":")[1];
        const secondsNew =
          Number(seconds) <= 0 || Number(seconds) >= 60
            ? "00"
            : Number(seconds) < 10 && onChange // only onChange 04:3 => 04:03
            ? "0" + Number(seconds)
            : seconds;

        return secondsNew;
      } else if (value == "0") {
        return "00";
      }

      return value;
    };

    return {
      validate: (event: any) => {
        const value = event.target.value;
        if (event.inputType != "deleteContentBackward") {
          if (value.split(":").length >= 3 && event.data == ":") {
            event.target.value = value.replace(":", "");
          } else if (value.length == 3 && value.indexOf(":") == 0) {
            // :50 => 00:50
            event.target.value = "00:" + validateSeconds(value);
          } else if (value.length == 2 && value.indexOf(":") == -1) {
            // 20 => 20:
            event.target.value = validateMinutes(value) + ":";
          } else if (value.length > 3) {
            event.target.value =
              validateMinutes(value) + ":" + validateSeconds(value);
          }
        }
      },
      change: (event: any) => {
        let value = event.target.value;
        if (value.length > 3) {
          value = validateMinutes(value) + ":" + validateSeconds(value, true);
        } else if (value.indexOf(":") >= 0) {
          // 4:4 => 04:04
          // 04:0 => 04:00
          value = validateMinutes(value) + ":" + validateSeconds(value, true);
        } else if (value.length == 1) {
          // 4 => 04:00
          value = validateMinutes(value) + ":" + validateSeconds("0", true);
        }

        event.target.value = value;
        emit("update:modelValue", value);
      },
      // updateMinutes: (event: any) => {
      //   let newValue = "00";
      //   let inputValue = minMaxValue(event.target.value);

      //   if (props.modelValue && props.modelValue.split(":").length == 2) {
      //     newValue = inputValue + ":" + props.modelValue.split(":")[1];
      //   } else {
      //     newValue = inputValue + ":00";
      //   }
      //   event.target.value = newValue;
      //   emit("update:modelValue", newValue);
      // },
      // updateSeconds: (event: any) => {
      //   let newValue = "";
      //   let inputValue = minMaxValue(event.target.value);

      //   if (props.modelValue && props.modelValue.split(":").length == 2) {
      //     newValue = props.modelValue.split(":")[0] + ":" + inputValue;
      //   } else {
      //     newValue = "00:" + inputValue;
      //   }

      //   emit("update:modelValue", newValue);
      // },
    };
  },
});
