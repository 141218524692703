import { Axios } from "./Axios";
import { ClubResponse } from "@/types/Club.Response";

export class ProfileClubAPI {
  get endpoint(): string {
    return "clubs";
  }

  async loadClubs(): Promise<ClubResponse[]> {
    return await Axios.instance.api
      .get<ClubResponse[]>(`${this.endpoint}`)
      .then(({ data }) => {
        return data;
      });
  }
}
