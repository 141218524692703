import { Axios } from "./Axios";
import { CompetitionGenerateResponse } from "@/types/Competition-Generate.Response";
import { CompetitionGenerateBody } from "@/types/Competition-Generate.Body";
import { CompetitionUpdateBody } from "@/types/Competition-Update.Body";
import { CompetitionUpdateResponse } from "@/types/Competition-Update.Response";
import { ListCreateBody } from "@/types/List-Create.Body";

export class ChampionshipClassListApi {
  get listEndpoint(): string {
    return `lists`;
  }

  async generate(
    body: CompetitionGenerateBody,
    simulate: boolean
  ): Promise<CompetitionGenerateResponse> {
    return await Axios.instance.api
      .post<CompetitionGenerateResponse>(
        `${this.listEndpoint}?simulate=${simulate}`,
        body
      )
      .then(({ data }) => {
        return data;
      });
  }

  async update(
    body: CompetitionUpdateBody
  ): Promise<CompetitionUpdateResponse> {
    return await Axios.instance.api
      .put<CompetitionUpdateResponse>(`${this.listEndpoint}`, body)
      .then(({ data }) => {
        return data;
      });
  }

  async loadList(listId: string): Promise<CompetitionGenerateResponse> {
    return await Axios.instance.api
      .get<CompetitionGenerateResponse>(`${this.listEndpoint}/${listId}`)
      .then(({ data }) => {
        return data;
      });
  }

  async exportListAsJSON(listId: string): Promise<any> {
    return await Axios.instance.api
      .get<any>(`export/${this.listEndpoint}/${listId}/?type=json`)
      .then(({ data }) => {
        return data;
      });
  }

  async exportListAsPDF(listId: string): Promise<Blob> {
    return await Axios.instance.api
      .get<Blob>(`export/${this.listEndpoint}/${listId}?type=pdf&langu=en-GB`, {
        responseType: "blob",
      })
      .then(({ data }) => {
        return data;
      });
  }

  async exportSimulateDataAsPDF(body: CompetitionGenerateBody): Promise<Blob> {
    return await Axios.instance.api
      .post<Blob>(
        `export/${this.listEndpoint}/${body.listId}?langu=en-GB`,
        body,
        {
          responseType: "blob",
        }
      )
      .then(({ data }) => {
        return data;
      });
  }

  async exportListsAsJSON(championshipId: string): Promise<any> {
    return await Axios.instance.api
      .get<any>(
        `export/championships/${championshipId}/${this.listEndpoint}?type=json`
      )
      .then(({ data }) => {
        return data;
      });
  }

  async setListSettings(
    listId: string,
    settings: ListCreateBody
  ): Promise<CompetitionGenerateResponse> {
    return await Axios.instance.api
      .put<CompetitionGenerateResponse>(`${this.listEndpoint}/${listId}`, {
        ...settings,
      })
      .then(({ data }) => {
        return data;
      });
  }

  async remove(id: string): Promise<void> {
    return await Axios.instance.api
      .delete<void>(`${this.listEndpoint}/${id}`)
      .then(({ data }) => data);
  }
}
