import { useRefreshStore } from "@/stores/Auth.store";
import {
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from "vue-router";

const mapPrivatePublic = (route: RouteRecordRaw) => {
  if (!route.meta) route.meta = {};
  route.meta.private = true;
  route.meta.public = false;

  return route;
};

export const privateRoutes: Array<RouteRecordRaw> = [
  {
    name: "Home",
    path: "/home",
    meta: { label: "view.home", isMainMenuItem: true, icon: "fa-house" },
    component: () => import("@/views/home.page.vue"),
  },
  {
    name: "Profile",
    path: "/profile",
    meta: { label: "view.profile", isMainMenuItem: true, icon: "fa-user" },
    component: () => import("@/views/profile.page.vue"),
  },
  {
    name: "Championship",
    path: "/championship/:id",
    component: () => import("@/views/championship.page.vue"),
    children: [
      {
        name: "Overview",
        path: "",
        component: () => import("@/views/championship-home.page.vue"),
      },
      {
        name: "Registrations",
        path: "registrations",
        component: () => import("@/views/championship-registrations.page.vue"),
      },
      {
        name: "Categories",
        path: "categories",
        component: () => import("@/views/championship-categories.page.vue"),
      },
      {
        name: "Dashboard",
        path: "dashboard",
        component: () => import("@/views/championship-dashboard.page.vue"),
      },
      {
        name: "Subscriptions",
        path: "subscriptions",
        component: () => import("@/views/championship-subscriptions.page.vue"),
      },
    ].map(mapPrivatePublic),
  },
  {
    name: "List",
    path: "/championship/:id/class/:classId/list/:listId",
    component: () => import("@/views/championship-class-list.page.vue"),
  },
].map(mapPrivatePublic);

export const privateBeforeEach = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
): void | Error | Promise<void | Error> => {
  const { isAuthenticated, refresh } = useRefreshStore();
  // public only?
  if (to.meta && to.meta.public && !to.meta.private) {
    // auto redirect to home
    if (isAuthenticated.value) {
      next({ name: "Home" });
    } else {
      next();
    }
    // privates routes
  } else if (to.meta && to.meta.private && !to.meta.public) {
    if (isAuthenticated.value) {
      next();
    } else {
      refresh()
        .then(() => {
          if (isAuthenticated.value) {
            next();
          } else {
            next({ name: "SignIn" });
          }
        })
        .catch(() => next({ name: "SignIn" }));
    }
  } else {
    next();
  }
};
