import { UpdateProfileBody } from "@/types/UpdateProfile.Body";
import { ProfileResponse } from "@/types/Profile.Response";
import { Axios } from "./Axios";
import { UpdateProfilePasswordBody } from "@/types/UpdateProfilePassword.Body";

export class ProfileApi {
  get endpoint() {
    return "users";
  }

  async loadMe(): Promise<ProfileResponse> {
    return await Axios.instance.api
      .get<ProfileResponse>(`${this.endpoint}/me`)
      .then(({ data }) => {
        return data;
      });
  }

  async updateMe(data: UpdateProfileBody): Promise<ProfileResponse> {
    return await Axios.instance.api
      .put<ProfileResponse>(`${this.endpoint}/me`, data)
      .then(({ data }) => {
        return data;
      });
  }

  async updatePassword(
    data: UpdateProfilePasswordBody
  ): Promise<ProfileResponse> {
    return await Axios.instance.api
      .put<ProfileResponse>(`${this.endpoint}/me/password`, data)
      .then(({ data }) => {
        return data;
      });
  }
}
