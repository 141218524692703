<template>
  <span
    v-show="isLoading"
    class="spinner-grow spinner-grow-sm"
    role="status"
    aria-hidden="true"
  ></span>
</template>
<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
    },
  },
};
</script>
