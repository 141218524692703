
import DefaultButton from "../common/button/default.button.vue";
import { useChampionshipClassListStore } from "@/stores/Championship-Class-List.store";
import { AthleteResponse } from "@/types/Athlete.Response";
import { CompetitionResponse } from "@/types/Competition.Response";
import { ResultResponse } from "@/types/Result.Response";
import { computed, ComputedRef, defineComponent, Ref, ref } from "vue";
import CompetitionPrintItem from "./competition-print.item.vue";

const textToHighlight: Ref<string> = ref("");

export default defineComponent({
  components: {
    CompetitionPrintItem,
    DefaultButton,
  },
  props: ["competition", "preview", "enableUpdateStatus"],
  emit: ["updateCalled", "updatePrepare"],
  setup(props, { emit }) {
    const { updateCalled, updatePrepare, setSelectedCompetition } =
      useChampionshipClassListStore();

    const now = ref(0);
    const timeStartedAt: Ref<undefined | number> = ref(undefined);
    let interval = 0;
    const deltaTimer: ComputedRef<string | undefined> = computed(() => {
      if (timeStartedAt?.value != undefined) {
        const startedAt: number = timeStartedAt.value;
        return new Date(now.value - startedAt).toTimeString().slice(3, 8);
      }
      return undefined;
    });

    return {
      getFirstLetter: (firstName = "") => {
        if (firstName.length > 0) {
          return firstName.charAt(0) + ".";
        }
        return "";
      },
      startTimer: () => {
        interval = setInterval(() => {
          now.value = Date.now();
          if (deltaTimer?.value && Number(deltaTimer.value.slice(0, 2)) >= 20) {
            now.value = 0;
            timeStartedAt.value = undefined;
            clearInterval(interval);
          }
        }, 1000);
        now.value = Date.now();
        timeStartedAt.value = Date.now();
      },
      deltaTimer,
      textToHighlight,
      isAthleteAWinner: (
        competition: CompetitionResponse,
        result: ResultResponse | undefined
      ): boolean | undefined => {
        if (
          competition &&
          (competition.athleteAIsEmpty || competition.athleteBIsEmpty)
        )
          return !!competition.athleteA;
        if (result) return result.athleteAPoints > result.athleteBPoints;
        return undefined;
      },
      canvasId: "CreateCompetitionResult",
      setSelectedCompetition,
      setStatus: () => {
        var called = false;
        var prepare = false;
        if (props.competition.isCalled) {
          called = false;
          prepare = false;
        } else if (props.competition.isPrepare) {
          called = true;
          updateCalled(props.competition.id, called);
          emit("updateCalled", called);
        } else {
          prepare = true;
          updatePrepare(props.competition.id, prepare);
          emit("updatePrepare", prepare);
        }
      },
      highlightAthlete: (athlete: AthleteResponse | undefined) => {
        if (athlete == undefined) return;
        const nextTextToHighlight = athlete.firstName + " " + athlete.lastName;
        if (textToHighlight.value == nextTextToHighlight)
          textToHighlight.value = "";
        else textToHighlight.value = nextTextToHighlight;
      },
    };
  },
});
