
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { RouteRecordRaw, useRouter } from "vue-router";
import { isMobile } from "@/utils";

export default defineComponent({
  setup() {
    const router = useRouter();

    const navItems: Array<RouteRecordRaw> = (router.getRoutes() || []).filter(
      (item) => item.meta?.label && item.meta?.isFooterMenuItem
    );

    const { locale, t, availableLocales } = useI18n();

    return {
      isMobile,
      t,
      navItems,
      locale,
      availableLocales,
      onLocaleChanged: (_locale: string) => {
        locale.value = _locale;
      },
    };
  },
});
