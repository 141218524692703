
import { timeout } from "@/services/timeout.service";
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  emits: ["onShown", "onHidden", "updateDisplay"],
  props: {
    canvasId: {
      type: String,
      default: () => "",
    },
  },
  setup: (props, { emit }) => {
    const canvasIsLoading = ref(false);
    onMounted(() => {
      const canvas = document.getElementById(props.canvasId);
      if (!canvas) return;

      canvas.addEventListener("show.bs.offcanvas", function () {
        canvasIsLoading.value = true;
      });
      canvas.addEventListener("hide.bs.offcanvas", function () {
        canvasIsLoading.value = true;
      });

      canvas.addEventListener("shown.bs.offcanvas", function () {
        emit("updateDisplay", true);
        emit("onShown");
        timeout(() => (canvasIsLoading.value = false));
      });
      canvas.addEventListener("hidden.bs.offcanvas", function () {
        emit("updateDisplay", false);
        emit("onHidden");
        timeout(() => (canvasIsLoading.value = false));
      });
    });

    return {
      canvasIsLoading,
    };
  },
});
