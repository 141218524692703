import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";

import i18n from "./i18n";
import router from "./router";

import { library } from "@fortawesome/fontawesome-svg-core";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import tooltip from "./directives/tooltip";

library.add(fas, fab, far);

const app = createApp(App)
  .directive("tooltip", tooltip)
  .use(i18n)
  .use(router)
  .component("fa-icon", FontAwesomeIcon)
  .mount("#app");

import "bootstrap/dist/js/bootstrap.js";
