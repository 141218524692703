import { ChampionshipRegistrationApi } from "@/api/Championship-Registration.api";
import { timeout } from "@/services/timeout.service";
import { NotificationTypes } from "@/types/NotificationTypes.enum";
import { RegistrationCreateBody } from "@/types/Registration-Create";
import { RegistrationImportBody } from "@/types/Registration-Import.Body";
import { RegistrationUpdateBody } from "@/types/Registration-Update.Body";
import { RegistrationResponse } from "@/types/Registration.Response";
import { computed, ComputedRef, reactive, ref, Ref } from "vue";
import { useI18n } from "vue-i18n";
import { useChampionshipClassStore } from "./Championship-Class.store";
import { ChampionshipRegistrationState } from "./Championship-Registration.state";
import { championshipState } from "./Championship.store";
import { useNotificationStore } from "./Notification.store";

const _championshipRegistrationState = reactive(
  {} as ChampionshipRegistrationState
);

const _championshipRegistrationApi = new ChampionshipRegistrationApi();

const registrationsAreLoading: ComputedRef<boolean> = computed(
  () => _championshipRegistrationState.registrationsAreLoading
);

const registrations: ComputedRef<RegistrationResponse[]> = computed(
  () => _championshipRegistrationState.registrations
);

const setSelectedRegistration = (value: RegistrationResponse) => {
  _championshipRegistrationState.selectedRegistration = value;
};

const selectedRegistration: ComputedRef<RegistrationResponse> = computed(
  () => _championshipRegistrationState.selectedRegistration
);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useChampionshipRegistrationStore() {
  const bulkInfoLogs: Ref<
    { type: string; message: string; data: any }[] | null
  > = ref(null);

  const { selectedClass } = useChampionshipClassStore();

  const { t } = useI18n();
  const { add } = useNotificationStore();

  const classRegistrations: ComputedRef<RegistrationResponse[]> = computed(
    () => {
      return selectedClass.value
        ? (registrations.value || []).filter((item) => {
            return item.classId == selectedClass.value.id;
          })
        : [];
    }
  );

  const clearStore = () => {
    _championshipRegistrationState.selectedRegistration =
      {} as RegistrationResponse;
    _championshipRegistrationState.registrations = [] as RegistrationResponse[];
  };

  const loadChampionshipRegistrations = async (silent = false) => {
    if (championshipState.championship?.id) {
      if (silent == false)
        _championshipRegistrationState.registrationsAreLoading = true;

      return _championshipRegistrationApi
        .loadRegistrations(championshipState.championship.id)
        .then((data) => {
          _championshipRegistrationState.registrations = data;
        })
        .catch(({ data }) => {
          add({
            title: t("errors.occurs-during-reading-championship-registrations"),
            details: data.message,
            tag: data.status,
            type: NotificationTypes.Error,
          });
        })
        .finally(() => {
          if (silent == false)
            timeout(() => {
              _championshipRegistrationState.registrationsAreLoading = false;
            });
        });
    }
  };

  const createRegistrations = async (
    registrationCreateBody: RegistrationCreateBody
  ) => {
    const championshipId = championshipState.championship?.id;
    if (championshipId)
      return _championshipRegistrationApi
        .create(championshipId, registrationCreateBody)
        .then((data) => {
          _championshipRegistrationState.registrations = data;
          add({
            title: t("success.registrations-successfully-created"),
            type: NotificationTypes.Success,
          });
        })
        .catch(({ data }) => {
          add({
            title: t(
              "errors.occurs-during-creating-championship-registrations"
            ),
            details: data.message,
            tag: data.status,
            type: NotificationTypes.Error,
          });
        });
  };

  const exportRegistrationAsPDF = async (
    type: string,
    groupBy: string,
    inNewTab = false
  ) => {
    const championship = championshipState.championship;
    if (championship)
      return _championshipRegistrationApi
        .exportPDF(
          championship.id,
          groupBy == "category" || groupBy == "weighed-status"
            ? type + "/category"
            : groupBy == "ranking" || groupBy == "nations"
            ? type + "/nation"
            : groupBy == "cross_check" && type == "athletes"
            ? type + "/cross_check"
            : groupBy == "club" && type == "athletes"
            ? type + "/club"
            : ""
        )
        .then((blob: Blob) => {
          if (blob) {
            const href = URL.createObjectURL(blob);
            const title = championship.name + "_" + groupBy + ".pdf";
            if (inNewTab) {
              try {
                const newTab = window.open(href, "_blank");
                if (newTab)
                  setTimeout(() => {
                    newTab.document.title = title;
                  }, 2500);
              } catch (e) {
                console.log(e);
              }
            } else {
              const anchor = document.createElement("a");
              anchor.href = href;
              anchor.target = "_blank";
              anchor.download = title;
              anchor.click();
              anchor.remove();
            }
          }
        })
        .catch(({ data }) => {
          add({
            title: t("errors.occurs-by-exporting-championship-registrations"),
            details: data.message,
            tag: data.status,
            type: NotificationTypes.Error,
          });
        });
  };

  const createRegistrationBulk = async (
    registrationImportBody: RegistrationImportBody[],
    simulate: boolean
  ) => {
    const championshipId = championshipState.championship?.id;
    if (championshipId)
      return _championshipRegistrationApi
        .createBulk(championshipId, registrationImportBody, simulate)
        .then((data) => {
          bulkInfoLogs.value = data.logList;
          if (!simulate) {
            /// im produktiven umfeld wird zeit benötigt.
            setTimeout(() => {
              loadChampionshipRegistrations(false);
            }, 1000);

            add({
              title: t("success.registrations-successfully-created"),
              type: NotificationTypes.Success,
            });
          } else {
            add({
              title: t("success.registrations-successfully-simulated"),
              type: NotificationTypes.Success,
            });
          }
        })
        .catch(({ data }) => {
          add({
            title: t(
              "errors.occurs-during-creating-championship-registrations"
            ),
            details: data.message,
            tag: data.status,
            type: NotificationTypes.Error,
          });
        });
  };

  const exportRegistrationsAsJSON = async () => {
    const championshipId = championshipState.championship?.id;
    if (championshipId)
      return _championshipRegistrationApi
        .exportJSON(championshipId)
        .then((json: string) => {
          if (json) {
            const anchor = document.createElement("a");
            anchor.href =
              "data:text/json;charset=utf-8," +
              encodeURIComponent(JSON.stringify(json));
            anchor.target = "_blank";
            anchor.download =
              (championshipState.championship?.name || "registrations") +
              ".json";
            anchor.click();
          }
        })
        .catch(({ data }) => {
          add({
            title: t(
              "errors.occurs-during-exporting-championship-registrations"
            ),
            details: data.message,
            tag: data.status,
            type: NotificationTypes.Error,
          });
        });
  };

  const exportRegistrationsAsCSV = async () => {
    const championshipId = championshipState.championship?.id;
    if (championshipId)
      return _championshipRegistrationApi
        .exportCSV(championshipId)
        .then((csv: string) => {
          if (csv) {
            const anchor = document.createElement("a");
            anchor.href =
              "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
            anchor.target = "_blank";
            anchor.download =
              (championshipState.championship?.name || "registrations") +
              ".csv";
            anchor.click();
          }
        })
        .catch(({ data }) => {
          add({
            title: t(
              "errors.occurs-during-exporting-championship-registrations"
            ),
            details: data.message,
            tag: data.status,
            type: NotificationTypes.Error,
          });
        });
  };

  const removeRegistration = async () => {
    const championshipId = championshipState.championship?.id;
    if (championshipId)
      return _championshipRegistrationApi
        .remove(championshipId, selectedRegistration.value.id)
        .then((data: RegistrationResponse[]) => {
          _championshipRegistrationState.registrations = data;
        })
        .catch(({ data }) => {
          add({
            title: t(
              "errors.occurs-during-removing-championship-registrations"
            ),
            details: data.message,
            tag: data.status,
            type: NotificationTypes.Error,
          });
          throw new Error();
        });
  };

  const removeNotWeightedRegistrations = async () => {
    const championshipId = championshipState.championship?.id;
    if (championshipId)
      return _championshipRegistrationApi
        .removeNotWeighted(championshipId)
        .then(() => {
          loadChampionshipRegistrations();
        })
        .catch(({ data }) => {
          add({
            title: t("errors.occurs-during-deleting-championship"),
            details: data.message,
            tag: data.status,
            type: NotificationTypes.Error,
          });
        });
  };

  const updateRegistration = async (registration: RegistrationUpdateBody) => {
    const championshipId = championshipState.championship?.id;
    if (championshipId)
      return _championshipRegistrationApi
        .update(
          championshipId,
          selectedRegistration.value.id,
          registration as RegistrationUpdateBody
        )
        .then((data: RegistrationResponse[]) => {
          _championshipRegistrationState.registrations = data;
        })
        .catch(({ data }) => {
          add({
            title: t(
              "errors.occurs-during-updating-championship-registrations"
            ),
            details: data.message,
            tag: data.status,
            type: NotificationTypes.Error,
          });
          throw new Error();
        });
  };

  return {
    bulkInfoLogs,
    removeRegistration,
    registrationsAreLoading,
    setSelectedRegistration,
    selectedRegistration,
    loadChampionshipRegistrations,
    createRegistrationBulk,
    createRegistrations,
    exportRegistrationsAsCSV,
    exportRegistrationsAsJSON,
    updateRegistration,
    registrations,
    classRegistrations,
    clearStore,
    exportRegistrationAsPDF,
    removeNotWeightedRegistrations,
  };
}
