
import { defineComponent, onMounted, watch, ref } from "vue";
import AppLayoutNavSidebar from "./components/common/layout/app-layout/app-layout.nav-sidebar.vue";
import AppLayoutContent from "./components/common/layout/app-layout/app-layout.content.vue";
import AppLayoutFooter from "./components/common/layout/app-layout/app-layout.footer.vue";
import AppNotification from "./components/notification/container.notification.vue";

import { useRoute } from "vue-router";
import CompetitionResultOffcanvas from "./components/lists/competition-result.offcanvas.vue";

import DefaultTransition from "./components/common/transitions/default.transition.vue";
import { useAuthStore } from "./stores/Auth.store";
import FullScreenLoadingIndicator from "./components/common/loading/full-screen.loading-indicator.vue";
import { useProfileStore } from "./stores/Profile.store";

export default defineComponent({
  components: {
    AppLayoutNavSidebar,
    AppLayoutContent,
    AppLayoutFooter,
    DefaultTransition,
    FullScreenLoadingIndicator,
    AppNotification,
    CompetitionResultOffcanvas,
  },
  setup: () => {
    const route = useRoute();

    const transitionName = ref("fade-in-out");
    const { isAuthenticated } = useAuthStore();

    const { setTheme } = useProfileStore();

    onMounted(() => {
      setTheme();

      watch(
        () => route.path,
        (to, from) => {
          if (to && from) {
            const toDepth = to.split("/").length;
            const fromDepth = from.split("/").length;
            transitionName.value =
              toDepth == fromDepth
                ? "fade-in-out"
                : toDepth < fromDepth
                ? "slide-right"
                : "slide-left";
          } else {
            transitionName.value = "fade-in-out";
          }
        }
      );
    });

    return {
      isAuthenticated,
      transitionName,
    };
  },
});
