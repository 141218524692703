
import { defineComponent, onBeforeMount, computed } from "vue";
import { RouteRecordRaw, useRouter } from "vue-router";
import { useAuthStore } from "../../../../stores/Auth.store";
import { useProfileStore } from "../../../../stores/Profile.store";
import { isMobile } from "@/utils";

export default defineComponent({
  setup() {
    const router = useRouter();
    const { me, load } = useProfileStore();
    onBeforeMount(() => load());

    const navItems: Array<{
      path: string;
      meta: { label: string; icon: string; displayBottom: boolean };
    }> = (router.getRoutes() || [])
      .filter(
        (item: RouteRecordRaw) => item.meta?.label && item.meta?.isMainMenuItem
      )
      .map((item: RouteRecordRaw) => {
        return {
          path: item.path,
          meta: {
            label: item.meta?.label as string,
            icon: item.meta?.icon as string,
            displayBottom: item.meta?.onlyMobile as boolean,
          },
        };
      });

    const { signOut } = useAuthStore();
    return {
      isMobile,
      me,
      navItems: computed(() =>
        navItems.filter((item) => !item.meta.displayBottom)
      ),
      navItemsBottom: computed(() =>
        navItems.filter((item) => item.meta.displayBottom && isMobile())
      ),
      appName: process.env.VUE_APP_NAME,
      onSignOut: async () => {
        await signOut();
        router.push("sign-in");
      },
    };
  },
});
