import { Axios } from "./Axios";
import { UpdateCompetitionPrepareBody } from "@/types/Competition-Prepare.Body";
import { UpdateCompetitionCalledBody } from "@/types/Competition-Called.Body";
import { UpdateCompetitionResultBody } from "@/types/Competition-Result.Body";

export class ChampionshipClassListCompetitionApi {
  getCompetitionEndpoint(listId: string): string {
    return `lists/${listId}/competitions`;
  }

  async updatePrepare(
    listId: string,
    body: UpdateCompetitionPrepareBody
  ): Promise<void> {
    return await Axios.instance.api.post(
      `${this.getCompetitionEndpoint(listId)}/prepare`,
      body
    );
  }

  async updateCalled(
    listId: string,
    body: UpdateCompetitionCalledBody
  ): Promise<void> {
    return await Axios.instance.api.post(
      `${this.getCompetitionEndpoint(listId)}/called`,
      body
    );
  }

  async createOrUpdateResult(
    listId: string,
    body: UpdateCompetitionResultBody
  ): Promise<void> {
    return await Axios.instance.api.post(
      `${this.getCompetitionEndpoint(listId)}/result`,
      body
    );
  }
}
