
import { defineComponent, onMounted, ref } from "vue";
import ButtonLoadingIndicator from "../loading/button.loading-indicator.vue";

export default defineComponent({
  components: { ButtonLoadingIndicator },
  emits: ["onClick"],
  props: [
    "hasError",
    "isLoading",
    "canvasId",
    "active",
    "disabled",
    "btnClass",
    "margin",
    "dataBsToggle",
    "dataBsTarget",
  ],
  setup(props, { emit }) {
    if (props.canvasId) {
      var refCanvasCloseButton: any = null;
      onMounted(() => {
        refCanvasCloseButton = document.querySelector(
          "#" + props.canvasId + " .btn-close"
        );
      });
    }
    return {
      classes: props.btnClass ? props.btnClass : "btn-outline-primary  mb-4",
      onClick: () => {
        emit("onClick", {
          closeCanvas: () => {
            if (refCanvasCloseButton) refCanvasCloseButton.click();
          },
        });
      },
    };
  },
});
