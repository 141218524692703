import { ChampionshipBody } from "@/types/Championship.Body";
import { ChampionshipResponse } from "@/types/Championship.Response";
import { SubscriptionResponse } from "@/types/Subscription.Response";
import { SubscriptionRoles } from "@/types/SubscriptionRoles.enum";
import { Axios } from "./Axios";
import { ClassResponse } from "@/types/Class.Response";

export class ChampionshipApi {
  get endpoint(): string {
    return "championships";
  }

  async loadPublic(): Promise<ChampionshipResponse[]> {
    return await Axios.instance.api
      .get<ChampionshipResponse[]>(`${this.endpoint}/public`)
      .then(({ data }) => {
        return data;
      });
  }

  async loadChampionshipPublic(
    id: string
  ): Promise<{ championship: ChampionshipResponse; classes: ClassResponse[] }> {
    return await Axios.instance.api
      .get<{ championship: ChampionshipResponse; classes: ClassResponse[] }>(
        `${this.endpoint}/public/${id}`
      )
      .then(({ data }) => {
        return data;
      });
  }

  async exportListAsPDF(listId: string): Promise<Blob> {
    return await Axios.instance.api
      .get<Blob>(`export/lists/${listId}/public?type=pdf&langu=en-GB`, {
        responseType: "blob",
      })
      .then(({ data }) => {
        return data;
      });
  }

  async loadChampionships(): Promise<ChampionshipResponse[]> {
    return await Axios.instance.api
      .get<ChampionshipResponse[]>(`${this.endpoint}/`)
      .then(({ data }) => {
        return data;
      });
  }

  async loadChampionship(id: string): Promise<ChampionshipResponse> {
    return await Axios.instance.api
      .get<ChampionshipResponse>(`${this.endpoint}/${id}`)
      .then(({ data }) => {
        return data;
      });
  }

  async create(
    championshipToCreate: ChampionshipBody
  ): Promise<ChampionshipResponse> {
    return await Axios.instance.api
      .post<ChampionshipResponse>(`${this.endpoint}/`, championshipToCreate)
      .then(({ data }) => {
        return data;
      });
  }

  async update(
    id: string,
    championship: ChampionshipBody
  ): Promise<ChampionshipResponse> {
    return await Axios.instance.api
      .put<ChampionshipResponse>(`${this.endpoint}/${id}`, championship)
      .then(({ data }) => {
        return data;
      });
  }

  async remove(id: string): Promise<void> {
    return await Axios.instance.api
      .delete<void>(`${this.endpoint}/${id}`)
      .then(({ data }) => data);
  }

  async updateSubscriptionRole(
    championshipId: string,
    subscriptionId: string,
    roleId: SubscriptionRoles
  ): Promise<SubscriptionResponse[]> {
    return await Axios.instance.api
      .put<SubscriptionResponse[]>(
        `${this.endpoint}/${championshipId}/subscriptions/${subscriptionId}`,
        { roleId }
      )
      .then(({ data }) => {
        return data;
      });
  }

  /// 404 - Not Found
  /// 409 - Already Subscribed
  /// 201 - Subscription Created
  async subscribe(code: string): Promise<boolean> {
    return await Axios.instance.api
      .post<boolean>(`${this.endpoint}/${code}/subscribe`)
      .then(({ data }) => {
        return data;
      });
  }

  async subscribeForUser(id: string, email: string): Promise<boolean> {
    return await Axios.instance.api
      .post<boolean>(`${this.endpoint}/${id}/${email}/subscribe`)
      .then(({ data }) => {
        return data;
      });
  }

  async unsubscribe(id: string): Promise<boolean> {
    return await Axios.instance.api
      .post<boolean>(`${this.endpoint}/${id}/unsubscribe`)
      .then(({ data }) => {
        return data;
      });
  }

  async loadSubscriptions(id: string): Promise<SubscriptionResponse[]> {
    return await Axios.instance.api
      .get<SubscriptionResponse[]>(`${this.endpoint}/${id}/subscriptions`)
      .then(({ data }) => {
        return data;
      });
  }
}
