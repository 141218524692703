import { ValueHelpResponse } from "@/types/ValueHelpResponse";
import { ValueHelpQuery } from "@/types/ValueHelpQuery.enum";
import { Axios } from "./Axios";

export class ValueHelpApi {
  get endpoint() {
    return `utils/value_help`;
  }

  async loadValueHelp(
    _valueHelp: ValueHelpQuery
  ): Promise<ValueHelpResponse[]> {
    return await Axios.instance.api
      .get<ValueHelpResponse[]>(`${this.endpoint}?code=${_valueHelp}`)
      .then(({ data }) => {
        return data;
      });
  }
}
