import { ChampionshipApi } from "@/api/Championship.api";
import { timeout } from "@/services/timeout.service";
import { ChampionshipBody } from "@/types/Championship.Body";
import { ChampionshipResponse } from "@/types/Championship.Response";
import { NotificationTypes } from "@/types/NotificationTypes.enum";
import { SubscriptionRoles } from "@/types/SubscriptionRoles.enum";
import { computed, ComputedRef, reactive } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useChampionshipClassStore } from "./Championship-Class.store";
import { useChampionshipRegistrationStore } from "./Championship-Registration.store";
import { ChampionshipState } from "./Championship.state";
import { useRegistrationImport } from "./Import-Registrations.store";
import { useNotificationStore } from "./Notification.store";

export const championshipState = reactive({} as ChampionshipState);

const _championshipApi = new ChampionshipApi();

const isHost: ComputedRef<boolean> = computed(
  () =>
    championshipState.championship?.subscriptionRoleId == SubscriptionRoles.Host
);

const isOrga: ComputedRef<boolean> = computed(
  () =>
    championshipState.championship?.subscriptionRoleId == SubscriptionRoles.Orga
);

const isGuest: ComputedRef<boolean> = computed(
  () =>
    championshipState.championship?.subscriptionRoleId ==
    SubscriptionRoles.Guest
);

const isLoading: ComputedRef<boolean> = computed(
  () => championshipState.isLoading
);
const championship: ComputedRef<ChampionshipResponse | undefined> = computed(
  () => championshipState.championship
);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useChampionshipStore() {
  const {
    loadChampionshipRegistrations,
    clearStore: clearChampionshipRegistrationStore,
  } = useChampionshipRegistrationStore();

  const { loadChampionshipClasses, clearStore: clearChampionshipClassStore } =
    useChampionshipClassStore();

  const { clearStore: clearRegistrationImportStore } = useRegistrationImport();

  const { add } = useNotificationStore();
  const { t } = useI18n();
  const { back } = useRouter();

  const clearStore = () => {
    championshipState.championship = {} as ChampionshipResponse;

    championshipState.championship = {} as ChampionshipResponse;
    clearChampionshipRegistrationStore();
    clearChampionshipClassStore();
    clearRegistrationImportStore();
  };

  const loadChampionship = async (id: string) => {
    clearStore();

    championshipState.isLoading = true;
    return _championshipApi
      .loadChampionship(id)
      .then((response) => {
        championshipState.championship = response;

        Promise.all([
          loadChampionshipRegistrations(),
          loadChampionshipClasses(),
        ]).then(() => {
          add({
            title: t("success.championship-successfully-read"),
            type: NotificationTypes.Success,
          });
        });
      })
      .catch(({ data }) => {
        add({
          title: t("errors.occurs-during-reading-championship"),
          details: data.message,
          tag: data.status,
          type: NotificationTypes.Error,
        });

        back();
      })
      .finally(() => {
        timeout(() => {
          championshipState.isLoading = false;
        });
      });
  };

  const create = async (championship: ChampionshipBody) => {
    return _championshipApi
      .create(championship)
      .then((response) => {
        championshipState.championship = response;
      })
      .catch(({ data }) => {
        add({
          title: t("errors.occurs-during-creating-championship"),
          details: data.message,
          tag: data.status,
          type: NotificationTypes.Error,
        });
      });
  };

  const update = async (championship: ChampionshipResponse) => {
    _championshipApi
      .update(championship.id, championship as ChampionshipBody)
      .then((response) => {
        championshipState.championship = response;
      })
      .catch(({ data }) => {
        add({
          title: t("errors.occurs-by-updating-championship"),
          details: data.message,
          tag: data.status,
          type: NotificationTypes.Error,
        });

        back();
      });
  };

  const remove = async (championshipId: string) => {
    return _championshipApi
      .remove(championshipId)
      .then(() => {
        back();
      })
      .catch(({ data }) => {
        add({
          title: t("errors.occurs-during-deleting-championship"),
          details: data.message,
          tag: data.status,
          type: NotificationTypes.Error,
        });
      });
  };

  return {
    clearStore,
    championship,
    isLoading,
    isGuest,
    isOrga,
    isHost,
    loadChampionship,
    create,
    update,
    remove,
  };
}
