import { reactive, ref } from "vue";
import { championshipDashboardApi } from "@/api/Championship-Dashboard.api";
import { useI18n } from "vue-i18n";
import { useNotificationStore } from "./Notification.store";
import { NotificationTypes } from "@/types/NotificationTypes.enum";
import { CompetitionResponse } from "@/types/Competition.Response";

const fightsTime = reactive({
  isLoading: false,
  averageTimeInSeconds: 0,
  leftTimeInSeconds: 0,
});

const weighedState = reactive({
  isLoading: false,
  countAll: 0,
  countWeighed: 0,
  perClass: {
    all: [],
    labels: [],
    weighedData: [],
    notWeighedData: [],
  },
});

const nextCompetitionPerClassState = reactive({
  isLoading: false,
  timestamp: new Date(),
  data: [] as { category: string; nextFights: CompetitionResponse[] }[],
});

const nationState = reactive({
  isLoading: false,
  nations: [],
  count: 0,
  ranking: {
    all: [],
    labels: ["test"],
    rankingCountFirst: [1],
    rankingCountSecond: [2],
    rankingCountThird: [3],
  },
});

const fightState = reactive({
  isLoading: false,
  all: [],
  count: 0,
  countClosed: 0,
  countFightless: 0,
  perClass: {
    labels: [],
    openCount: [],
    closedCount: [],
    fightlessCount: [],
  },
});

const hasLicense = ref(false);

const _championshipDashboardApi = new championshipDashboardApi();

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useChampionshipDashboardStore() {
  const { t } = useI18n();
  const { add } = useNotificationStore();

  const loadWeighedState = async (id: string) => {
    if (!hasLicense.value) return;

    return _championshipDashboardApi
      .loadWeighedKPI(id)
      .then((data) => {
        weighedState.countAll = data.weighedCount + data.notWeighedCount;
        weighedState.countWeighed = data.weighedCount;
        weighedState.perClass = data.classes;
      })
      .catch((error) => {
        add({
          title: t("error.loading-failed"),
          details: error.message,
          type: NotificationTypes.Error,
        });
      });
  };

  const loadFightTime = async (id: string) => {
    if (!hasLicense.value) return;

    fightsTime.isLoading = true;
    return _championshipDashboardApi
      .loadFightTime(id)
      .then((data) => {
        fightsTime.averageTimeInSeconds = data.averageTimeInSeconds;
        fightsTime.leftTimeInSeconds = data.leftTimeInSeconds;
      })
      .catch((error) => {
        add({
          title: t("error.loading-failed"),
          details: error.message,
          type: NotificationTypes.Error,
        });
      })
      .finally(() => {
        fightsTime.isLoading = false;
      });
  };

  const loadNationRanking = async (id: string) => {
    if (!hasLicense.value) return;

    return _championshipDashboardApi
      .loadNationRanking(id)
      .then((data) => {
        nationState.ranking = data.nationsRanking;
      })
      .catch((error) => {
        add({
          title: t("error.loading-failed"),
          details: error.message,
          type: NotificationTypes.Error,
        });
      });
  };

  const loadNationState = async (id: string) => {
    if (!hasLicense.value) return;

    return _championshipDashboardApi
      .loadRegistrationNationKPI(id)
      .then((data) => {
        nationState.count = data.nationsCount;
        nationState.nations = data.nations;
      })
      .catch((error) => {
        add({
          title: t("error.loading-failed"),
          details: error.message,
          type: NotificationTypes.Error,
        });
      });
  };

  const loadListKPI = async (id: string) => {
    if (!hasLicense.value) return;
    return _championshipDashboardApi
      .loadListKPI(id)
      .then((data) => {
        fightState.all = data.all;
        fightState.count = data.count;
        fightState.countClosed = data.countClosed;
        fightState.perClass = data.classes;
      })
      .catch((error) => {
        add({
          title: t("error.loading-failed"),
          details: error.message,
          type: NotificationTypes.Error,
        });
      });
  };

  const loadNextFightPerCategory = async (id: string) => {
    if (!hasLicense.value) return;
    return _championshipDashboardApi
      .loadNextFightPerCategory(id)
      .then((data) => {
        nextCompetitionPerClassState.timestamp = new Date();
        nextCompetitionPerClassState.data = data;
      })
      .catch((error) => {
        add({
          title: t("error.loading-failed"),
          details: error.message,
          type: NotificationTypes.Error,
        });
      });
  };

  const load = async (id: string) => {
    weighedState.isLoading = true;
    fightState.isLoading = true;
    nationState.isLoading = true;
    nextCompetitionPerClassState.isLoading = true;

    hasLicense.value = await _championshipDashboardApi.checkLicense(id);

    loadWeighedState(id).finally(() => {
      weighedState.isLoading = false;
    });

    loadListKPI(id).finally(() => {
      fightState.isLoading = false;
    });

    Promise.all([loadNationRanking(id), loadNationState(id)]).finally(() => {
      nationState.isLoading = false;
    });

    loadFightTime(id);

    loadNextFightPerCategory(id).finally(() => {
      nextCompetitionPerClassState.isLoading = false;
    });
  };

  return {
    load,
    weighedState,
    nationState,
    fightState,
    loadNextFightPerCategory,
    nextCompetitionPerClassState,
    fightsTime,
    hasLicense,
  };
}
