import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7b35193d"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled", "data-bs-toggle", "data-bs-target"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_button_loading_indicator = _resolveComponent("button-loading-indicator")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["btn", 
      _ctx.classes +
      (_ctx.hasError && _ctx.active
        ? 'shake active'
        : _ctx.hasError
        ? ' shake'
        : _ctx.active
        ? ' active'
        : '')
    ]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClick())),
    style: _normalizeStyle('margin: ' + (_ctx.margin ? _ctx.margin : '0px')),
    disabled: _ctx.isLoading || _ctx.disabled,
    "data-bs-toggle": _ctx.dataBsToggle,
    "data-bs-target": _ctx.dataBsTarget
  }, [
    _createVNode(_component_button_loading_indicator, { isLoading: _ctx.isLoading }, null, 8, ["isLoading"]),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 14, _hoisted_1))
}