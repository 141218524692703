import { SignInBody } from "../types/SignIn.Body";
import { SignUpBody } from "../types/SignUp.Body";
import { AuthResponse } from "../types/Auth.Response";
import { Axios } from "./Axios";
import { VerifyEmailBody } from "@/types/VerifyEmail.Body";

export class AuthAPI {
  get endpoint() {
    return "auth";
  }

  async signIn(body: SignInBody): Promise<AuthResponse> {
    return await Axios.instance.api
      .post<AuthResponse>(`${this.endpoint}/sign_in`, body)
      .then(({ data }) => {
        if (data.access_token) {
          localStorage.setItem(Axios.LOCAL_STORAGE_KEY, JSON.stringify(data));
        }
        return data as AuthResponse;
      });
  }

  async signUp(body: SignUpBody): Promise<void> {
    body.callBackUrl = process.env.VUE_APP_ROOT + "/verify-email";
    await Axios.instance.api.post<AuthResponse>(
      `${this.endpoint}/sign_up`,
      body
    );
  }

  async verifyEmail(body: VerifyEmailBody): Promise<void> {
    await Axios.instance.api.post<AuthResponse>(
      `${this.endpoint}/verify_email`,
      body
    );
  }

  async appHasRefreshToken(): Promise<boolean> {
    const refreshToken = await this.getRefreshToken();

    return !!refreshToken;
  }

  async getRefreshToken(): Promise<string> {
    const localStorageItem = localStorage.getItem(Axios.LOCAL_STORAGE_KEY);

    const access = JSON.parse(localStorageItem ? localStorageItem : "");
    return access?.refresh_token;
  }

  async refreshToken(refreshToken: string): Promise<AuthResponse> {
    return await Axios.instance.api
      .post<AuthResponse>(`${this.endpoint}/refresh_token`, {
        refreshToken,
      })
      .then(async ({ data }) => {
        if (data.access_token) {
          await localStorage.setItem(
            Axios.LOCAL_STORAGE_KEY,
            JSON.stringify(data)
          );
        }
        return data;
      });
  }

  async signOut(): Promise<void> {
    return await Axios.instance.api
      .post(`${this.endpoint}/sign_out`)
      .then(() => {
        localStorage.removeItem(Axios.LOCAL_STORAGE_KEY);
      });
  }
}
