<template>
  <div
    class="loading-full-screen"
    style="
      height: 100%;
      width: 100%;
      position: fixed;
      z-index: 9998;
      opacity: 0.5;
    "
    v-if="isLoading"
  ></div>
  <div
    style="height: 100%; width: 100%; position: fixed; z-index: 9999"
    v-if="isLoading"
  >
    <default-loading-indicator> </default-loading-indicator>
  </div>
</template>
<script>
import DefaultLoadingIndicator from "./default.loading-indicator.vue";
export default {
  components: { DefaultLoadingIndicator },
  props: {
    isLoading: {
      type: Boolean,
    },
  },
};
</script>
