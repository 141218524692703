
import { CompetitionResponse } from "@/types/Competition.Response";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    competition: {
      type: Object as PropType<CompetitionResponse>,
    },
  },
});
