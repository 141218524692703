import { RegistrationCreateBody } from "@/types/Registration-Create";
import { RegistrationImportBody } from "@/types/Registration-Import.Body";
import { RegistrationUpdateBody } from "@/types/Registration-Update.Body";
import { RegistrationResponse } from "@/types/Registration.Response";
import { Axios } from "./Axios";

export class ChampionshipRegistrationApi {
  getEndpoint(id: string): string {
    return `championships/${id}/registrations`;
  }

  async loadRegistrations(
    championshipId: string
  ): Promise<RegistrationResponse[]> {
    return await Axios.instance.api
      .get<RegistrationResponse[]>(`${this.getEndpoint(championshipId)}`)
      .then(({ data }) => {
        return data;
      });
  }

  async create(
    championshipId: string,
    registrationCreateBody: RegistrationCreateBody
  ) {
    return await Axios.instance.api
      .post(`${this.getEndpoint(championshipId)}`, registrationCreateBody)
      .then(({ data }) => {
        return data;
      });
  }

  async createBulk(
    championshipId: string,
    registrationImportBody: RegistrationImportBody[],
    simulate = false
  ): Promise<any> {
    return await Axios.instance.api
      .post(
        `import/${championshipId}/registrations?simulate=${simulate}`,
        registrationImportBody
      )
      .then(({ data }) => {
        return data;
      });
  }

  async update(
    championshipId: string,
    registrationId: string,
    registrationUpdateBody: RegistrationUpdateBody
  ) {
    return await Axios.instance.api
      .put<RegistrationResponse[]>(
        `${this.getEndpoint(championshipId)}/${registrationId}/`,
        registrationUpdateBody
      )
      .then(({ data }) => {
        return data;
      });
  }

  async removeNotWeighted(championshipId: string): Promise<void> {
    return await Axios.instance.api
      .delete<void>(`${this.getEndpoint(championshipId)}/`)
      .then(({ data }) => data);
  }

  async remove(championshipId: string, registrationId: string) {
    return await Axios.instance.api
      .delete<RegistrationResponse[]>(
        `${this.getEndpoint(championshipId)}/${registrationId}/`
      )
      .then(({ data }) => {
        return data;
      });
  }

  async exportJSON(championshipId: string) {
    return await Axios.instance.api
      .get<string>(`export/${this.getEndpoint(championshipId)}/?type=json`)
      .then((response) => {
        return response.data;
      });
  }

  async exportPDF(championshipId: string, groupBy: string): Promise<Blob> {
    return await Axios.instance.api
      .get<Blob>(
        `export/${this.getEndpoint(
          championshipId
        )}/?type=pdf&langu=de-DE&groupBy=${groupBy}`,
        { responseType: "blob" }
      )
      .then(({ data }) => {
        return data;
      });
  }

  async exportCSV(championshipId: string) {
    return await Axios.instance.api
      .get<string>(`export/${this.getEndpoint(championshipId)}?type=csv`)
      .then((response) => {
        return response.data;
      });
  }
}
