import { createI18n, I18n } from "vue-i18n";

const DEFAULT_LOCALE = "en-EN";

export const SUPPORT_LOCALES = ["en-EN", "ja-JA", "de-DE"];

function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const messages: any = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

export function setupI18n(
  options = {
    locale: DEFAULT_LOCALE,
    legacy: false,
    messages: loadLocaleMessages(),
  }
): I18n {
  const i18n = createI18n(options);
  setI18nLanguage(i18n, options.locale);
  return i18n;
}

export function setI18nLanguage(i18n: I18n, locale: string): void {
  if (i18n.mode === "legacy" && typeof i18n.global.locale == "string") {
    i18n.global.locale = locale;
  } else if (typeof i18n.global.locale == "object") {
    i18n.global.locale.value = locale;
  }
}

const i18n = setupI18n();

export default i18n;
