import { ClassCreateBody } from "@/types/Class-Create.Body";
import { ClassBody } from "@/types/Class.Body";
import { ClassResponse } from "@/types/Class.Response";
import { ValueHelpResponse } from "@/types/ValueHelpResponse";
import { Axios } from "./Axios";

export class ChampionshipClassApi {
  getEndpoint(id: string): string {
    return `championships/${id}/classes`;
  }

  async loadClasses(championshipId: string): Promise<ClassResponse[]> {
    return await Axios.instance.api
      .get<ClassResponse[]>(`${this.getEndpoint(championshipId)}/`)
      .then(({ data }) => {
        return data;
      });
  }

  async loadClass(
    championshipId: string,
    classId: string
  ): Promise<ClassResponse[]> {
    return await Axios.instance.api
      .get<ClassResponse[]>(`${this.getEndpoint(championshipId)}/${classId}`)
      .then(({ data }) => {
        return data;
      });
  }

  async create(championshipId: string, classes: ClassCreateBody) {
    return await Axios.instance.api
      .post<ClassResponse[]>(`${this.getEndpoint(championshipId)}`, classes)
      .then(({ data }) => {
        return data;
      });
  }

  async update(
    championshipId: string,
    classId: string,
    classData: ClassBody
  ): Promise<ClassResponse> {
    return await Axios.instance.api
      .put<ClassResponse>(
        `${this.getEndpoint(championshipId)}/${classId}`,
        classData
      )
      .then(({ data }) => {
        return data;
      });
  }

  async getWeighClassDefaults(
    ageClassText: string
  ): Promise<ValueHelpResponse[]> {
    return await Axios.instance.api
      .get<ValueHelpResponse[]>(`weight_classes/defaults_by/${ageClassText}`)
      .then(({ data }) => {
        return data;
      });
  }
}
