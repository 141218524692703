import { RouteRecordRaw } from "vue-router";

export const publicRoutes: Array<RouteRecordRaw> = [
  {
    name: "Privacy",
    label: "Privacy",
    path: "/privacy",
    meta: {
      label: "view.privacy",
      icon: "fa-circle-info",
      isMainMenuItem: true,
      onlyMobile: true,
    },
    component: () => import("@/views/privacy.page.vue"),
  },
  {
    name: "Legal",
    path: "/legal",
    meta: {
      label: "view.legal",
      isMainMenuItem: true,
      icon: "fa-gavel",
      onlyMobile: true,
    },
    component: () => import("@/views/legal.page.vue"),
  },
].map((route: RouteRecordRaw) => {
  if (!route.meta) route.meta = {};
  route.meta.private = true;
  route.meta.public = true;
  route.meta.isFooterMenuItem = true;
  return route;
});
