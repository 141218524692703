import Schema, { Rule, ValidateError } from "async-validator";
import { computed, ref, Ref, ReactiveEffect } from "vue";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useValidatorService = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  data: any,
  rules: Record<string, Rule>
) => {
  const validator = new Schema(rules);

  const errors: Ref<ValidateError[]> = ref([]);

  return {
    validate: (_data = data) => {
      validator.validate(_data, (_errors: ValidateError[] | null) => {
        if (_errors) errors.value = _errors;
        else errors.value = [];
      });
      return errors.value.length == 0;
    },
    errorMap: computed(() => {
      const resultMap = new Map<string, string | undefined>();
      errors.value.forEach((error: ValidateError) => {
        return error.field
          ? resultMap.set(error.field.toString(), error.message)
          : undefined;
      });
      return resultMap;
    }),
    errors,
  };
};
