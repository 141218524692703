import { ChampionshipClassApi } from "@/api/Championship-Class.api";
import { timeout } from "@/services/timeout.service";
import { useValidatorService } from "@/services/validation.service";
import { ClassCreateBody } from "@/types/Class-Create.Body";
import { ClassResponse } from "@/types/Class.Response";
import { NotificationTypes } from "@/types/NotificationTypes.enum";
import { ValueHelpResponse } from "@/types/ValueHelpResponse";
import { InternalRuleItem, Rule } from "async-validator/dist-types/interface";
import { computed, ComputedRef, reactive, watch } from "vue";
import { useI18n } from "vue-i18n";
import { ChampionshipClassState } from "./Championship-Class.state";
import { championshipState } from "./Championship.store";
import { useNotificationStore } from "./Notification.store";
import { useValueHelpState } from "./ValueHelp.store";

const _championshipClassState = reactive({} as ChampionshipClassState);

const _championshipClassApi = new ChampionshipClassApi();

const classesAreLoading: ComputedRef<boolean> = computed(
  () => _championshipClassState.classesAreLoading
);

const classes: ComputedRef<ClassResponse[]> = computed(
  () => _championshipClassState.classes || []
);

const classRegistrationsAreLoading: ComputedRef<boolean> = computed(
  () => _championshipClassState.classRegistrationsAreLoading
);

const optionsClasses: ComputedRef<{ value: string; label: string }[]> =
  computed(() =>
    classes.value.map((item) => {
      return {
        value: item.id,
        label: `${item.gender} | ${item.ageClassText} | ${item.weightClassText}`,
      };
    })
  );

const selectedClass: ComputedRef<ClassResponse> = computed(
  () => _championshipClassState.selectedClass
);

const classCreateData = reactive({} as ClassCreateBody);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useChampionshipClassStore() {
  // dependencies
  const { t } = useI18n();
  const { add } = useNotificationStore();

  // mutations
  const setSelectedClass = (value: ClassResponse, silent = false) => {
    if (silent == false)
      _championshipClassState.classRegistrationsAreLoading = true;

    _championshipClassState.selectedClass = value;

    timeout(
      () => (_championshipClassState.classRegistrationsAreLoading = false)
    );
  };

  const clearStore = () => {
    _championshipClassState.selectedClass = {} as ClassResponse;
    _championshipClassState.classes = [] as ClassResponse[];
  };

  const loadChampionshipClasses = async (silent = false) => {
    if (silent == false) _championshipClassState.classesAreLoading = true;

    if (championshipState.championship?.id)
      return _championshipClassApi
        .loadClasses(championshipState.championship.id)
        .then((data) => {
          _championshipClassState.classes = data;
        })
        .catch(({ data }) => {
          add({
            title: t("errors.occurs-during-reading-championship-classes"),
            details: data.message,
            tag: data.status,
            type: NotificationTypes.Error,
          });
        })
        .finally(() => {
          if (silent == false)
            timeout(() => {
              _championshipClassState.classesAreLoading = false;
            });
        });
  };

  const loadDefaultClasses = async (label: string) => {
    if (classCreateData?.ageClassId) {
      return _championshipClassApi
        .getWeighClassDefaults(label)
        .then((data: ValueHelpResponse[]) => {
          classCreateData.weightClassIds = data.map((item) => item.value);
        })
        .catch(({ data }) => {
          add({
            title: t("errors.occurs-during-reading-default-classes"),
            details: data.message,
            tag: data.status,
            type: NotificationTypes.Error,
          });
        });
    }
  };

  const rules: Record<string, Rule> = {
    gender: {
      required: true,
      validator: (rule: InternalRuleItem, value: string) => {
        if (!value) return new Error(t("errors.field-is_required"));
        if (!(value == "M" || value == "F" || value == "Mixed"))
          return new Error(t("errors.gender-wrong-value"));
        return [];
      },
    },
    weightClassIds: {
      validator: (rule: InternalRuleItem, value: string[]) => {
        if (!value) return new Error(t("errors.field-is_required"));
        if (Array.isArray(value) && value.length > 0) return [];
        return new Error(t("errors.please-select-at-least-one-item"));
      },
    },
    ageClassId: {
      required: true,
      validator: (rule: InternalRuleItem, value: string[]) => {
        if (!value)
          return new Error(t("errors.please-select-at-least-one-item"));
        return [];
      },
    },
  };

  const { validate, errorMap } = useValidatorService(classCreateData, rules);
  const { valueHelpState } = useValueHelpState();

  watch(classCreateData, () => {
    validate();
  });

  watch(
    () => classCreateData.ageClassId,
    (newValue: string, oldValue: string) => {
      if (newValue == oldValue) return;
      if (newValue != "" && newValue !== undefined) {
        const ageClass = valueHelpState.value.ageClasses.find(
          (item) => item.value == newValue
        );
        if (ageClass) loadDefaultClasses(ageClass.label);
      }
    }
  );

  validate();

  const createClasses = async (classes: ClassCreateBody) => {
    if (championshipState.championship)
      return _championshipClassApi
        .create(championshipState.championship.id, classes)
        .then((data) => {
          _championshipClassState.classes = data;
          add({
            title: t("success.classes-successfully-created"),
            details: "",
            tag: "201",
            type: NotificationTypes.Success,
          });
        })
        .catch(({ data }) => {
          add({
            title: t("errors.occurs-during-creating-championship-classes"),
            details: data.message,
            tag: data.status,
            type: NotificationTypes.Error,
          });
        })
        .finally(() => {
          timeout(() => {
            _championshipClassState.classesAreLoading = false;
          });
        });
  };

  return {
    clearStore,
    classCreateData,
    validate,
    errorMap,
    classesAreLoading,
    classRegistrationsAreLoading,
    classes,
    optionsClasses,
    setSelectedClass,
    selectedClass,
    loadChampionshipClasses,
    createClasses,
  };
}
