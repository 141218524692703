export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}

export const alphabetical = (arr: any[], getter: any, order = "asc") =>
  arr.sort(
    order === "desc"
      ? (a: any, b: any) => getter(b).localeCompare(getter(a))
      : (a: any, b: any) => getter(a).localeCompare(getter(b))
  );

export const csvToJson = (csv: string) => {
  const lines = csv.split("\n");

  const result = [];

  const headers = lines[0].split(",");

  for (let i = 1; i < lines.length; i++) {
    const obj = {} as any;
    const currentline = lines[i].split(",");

    for (let j = 0; j < headers.length; j++) {
      obj[headers[j].replaceAll('"', "")] = currentline[j].replaceAll('"', "");
    }

    result.push(obj);
  }

  //return result; //JavaScript object
  return JSON.stringify(result); //JSON
};
