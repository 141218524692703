import { ChampionshipClassListCompetitionApi } from "@/api/Championship-Class-List-Competition.api";
import { ChampionshipClassListApi } from "@/api/Championship-Class-List.api";
import { timeout } from "@/services/timeout.service";
import { ClassResponse } from "@/types/Class.Response";
import { CompetitionGenerateResponse } from "@/types/Competition-Generate.Response";
import { UpdateCompetitionResultBody } from "@/types/Competition-Result.Body";
import { CompetitionResponse } from "@/types/Competition.Response";
import { NotificationTypes } from "@/types/NotificationTypes.enum";
import { RegistrationResponse } from "@/types/Registration.Response";
import { computed, ComputedRef, reactive } from "vue";
import { useI18n } from "vue-i18n";
import { ChampionshipClassListState } from "./Championship-Class-List.state";
import { useChampionshipClassStore } from "./Championship-Class.store";
import { useChampionshipRegistrationStore } from "./Championship-Registration.store";
import { useChampionshipStore } from "./Championship.store";
import { useNotificationStore } from "./Notification.store";

const _championshipClassListState = reactive({} as ChampionshipClassListState);

const selectedClassListIsLoading: ComputedRef<boolean> = computed(
  () => _championshipClassListState.selectedClassListIsLoading
);

const selectedClassList: ComputedRef<CompetitionGenerateResponse> = computed(
  () => _championshipClassListState.selectedClassList
);

const selectedCompetition: ComputedRef<CompetitionResponse> = computed(
  () => _championshipClassListState.selectedCompetition
);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useChampionshipClassListStore() {
  const _championshipClassListApi = new ChampionshipClassListApi();

  const _championshipClassListCompetitionApi =
    new ChampionshipClassListCompetitionApi();

  const { championship, loadChampionship } = useChampionshipStore();

  const { classRegistrations } = useChampionshipRegistrationStore();

  const { classes, selectedClass, setSelectedClass, loadChampionshipClasses } =
    useChampionshipClassStore();

  const { t } = useI18n();
  const { add } = useNotificationStore();

  const setSelectedCompetition = (value: CompetitionResponse) => {
    _championshipClassListState.selectedCompetition = value;
  };

  const exportListAsJSON = async (listId: string) => {
    return _championshipClassListApi
      .exportListAsJSON(listId)
      .then((json: string) => {
        if (json) {
          const anchor = document.createElement("a");
          anchor.href =
            "data:text/json;charset=utf-8," +
            encodeURIComponent(JSON.stringify(json));
          anchor.target = "_blank";
          anchor.download = (championship.value?.name || "list") + ".json";
          anchor.click();
          anchor.remove();
        }
      })
      .catch(({ data }) => {
        add({
          title: t("errors.occurs-by-exporting-championship-class-list"),
          details: data.message,
          tag: data.status,
          type: NotificationTypes.Error,
        });
      });
  };

  const exportListAsPDF = async (category: ClassResponse, download = false) => {
    return _championshipClassListApi
      .exportListAsPDF(category.listId)
      .then((blob: Blob) => {
        if (blob) {
          const href = URL.createObjectURL(blob);
          const title =
            category.gender +
            " " +
            category.ageClassText +
            " " +
            category.weightClassText +
            ".pdf";
          if (download) {
            const anchor = document.createElement("a");
            anchor.href = href;
            anchor.target = "_blank";
            anchor.download = title;
            anchor.click();
            anchor.remove();
          } else {
            try {
              const newTab = window.open(href, "_blank");
              if (newTab)
                setTimeout(() => {
                  newTab.document.title = title;
                }, 2500);
            } catch (e) {
              console.log(e);
            }
          }
        }
      })
      .catch(({ status, data }) => {
        {
          console.log(data);
          add({
            title: t("errors.occurs-by-exporting-championship-class-list"),
            details: "",
            tag: status,
            type: NotificationTypes.Error,
          });
        }
      });
  };

  const exportListsAsJSON = async (championshipId: string) => {
    return _championshipClassListApi
      .exportListsAsJSON(championshipId)
      .then((json: string) => {
        if (json) {
          const anchor = document.createElement("a");
          anchor.href =
            "data:text/json;charset=utf-8," +
            encodeURIComponent(JSON.stringify(json));
          anchor.target = "_blank";
          anchor.download = (championship.value?.name || "lists") + ".json";
          anchor.click();
        }
      })
      .catch(({ data }) => {
        add({
          title: t("errors.occurs-by-exporting-championship-class-list"),
          details: data.message,
          tag: data.status,
          type: NotificationTypes.Error,
        });
      });
  };

  // const setExecutionMode = async (executionModeId: string | undefined) => {
  //   if (!selectedClass.value) return;

  //   return _championshipClassListApi
  //     .updateExecutionMode(selectedClass.value.listId, executionModeId)
  //     .then(async (data) => {
  //       _championshipClassListState.selectedClassList = data;

  //       await loadChampionshipClasses(true);

  //       const _selectedClass: ClassResponse | undefined = classes.value.find(
  //         (_class: ClassResponse) => _class.id == selectedClass.value.id
  //       );
  //       6;

  //       if (_selectedClass) {
  //         setSelectedClass(_selectedClass, true);
  //       } else {
  //         add({
  //           title: t("errors.occurs-class-not-found"),
  //           type: NotificationTypes.Error,
  //         });
  //       }
  //     })
  //     .catch(({ data }) => {
  //       add({
  //         title: t(
  //           "errors.occurs-during-setting-championship-class-execution-mode"
  //         ),
  //         details: data.message,
  //         tag: data.status,
  //         type: NotificationTypes.Error,
  //       });
  //     });
  // };

  const remove = async () => {
    if (!selectedClass.value) return;

    return _championshipClassListApi
      .remove(selectedClass.value.listId)
      .then(async () => {
        await loadChampionshipClasses(true);

        const _selectedClass: ClassResponse | undefined = classes.value.find(
          (_class: ClassResponse) => _class.id == selectedClass.value.id
        );

        if (_selectedClass) {
          setSelectedClass(_selectedClass, true);
        } else {
          add({
            title: t("errors.occurs-class-not-found"),
            type: NotificationTypes.Error,
          });
        }
      })
      .catch(({ data }) => {
        add({
          title: t("errors.occurs-during-deleting-list"),
          details: data.message,
          tag: data.status,
          type: NotificationTypes.Error,
        });
      });
  };

  const _getListCreateData = () => {
    if (!championship.value?.id)
      add({
        title: t("errors.occurs-championship-not-found"),
        type: NotificationTypes.Error,
      });

    if (!selectedClass.value)
      add({
        title: t("errors.occurs-class-not-found"),
        type: NotificationTypes.Error,
      });

    // nutze bereits simulierte / geänderte registrierungen anstatt die class registrations, falls verfügbar
    let registrations: RegistrationResponse[] = classRegistrations.value;
    let useOverwrittenRegistrations = false;

    if (
      selectedClassList.value &&
      Array.isArray(selectedClassList.value.registrations) &&
      selectedClassList.value.registrations.findIndex(
        (registration) =>
          registration.startNumber == undefined || registration.startNumber == 0
      ) != 0
    ) {
      registrations = selectedClassList.value.registrations;
      useOverwrittenRegistrations = true;
    }

    return {
      listId: selectedClass.value.listId,
      registrations: registrations,
      executionModeId: selectedClass.value.executionModeId,
      raffleOptions: {
        fields: ["countryName", "associationName", "clubName"],
        overwriteStartNumbers: !useOverwrittenRegistrations,
      },
    };
  };

  const updateList = async (body: any) => {
    return _championshipClassListApi
      .update(body)
      .then(async () => {
        await loadChampionshipClasses(true);
      })
      .catch(({ data }) => {
        add({
          title: t("errors.occurs-during-modifing-of-list"),
          details: data.message,
          tag: data.status,
          type: NotificationTypes.Error,
        });
      });
  };

  const findList = async (
    championshipId: string,
    listId: string,
    silent = false
  ) => {
    if (silent == false) {
      _championshipClassListState.selectedClassListIsLoading = true;
      await loadChampionship(championshipId);
    }

    if (Array.isArray(classes.value) && classes.value.length == 0)
      await loadChampionshipClasses(true);

    const _selectedClass = classes.value.find((item) => item.listId == listId);
    if (_selectedClass) {
      setSelectedClass(_selectedClass);
    } else {
      add({
        title: t("errors.occurs-class-not-found"),
        details: "",
        tag: "",
        type: NotificationTypes.Error,
      });
    }

    await _championshipClassListApi
      .loadList(listId)
      .then((data) => {
        _championshipClassListState.selectedClassList = data;
      })
      .catch(({ data }) => {
        add({
          title: t("errors.occurs-during-reading-championship-class-list"),
          details: data.message,
          tag: data.status,
          type: NotificationTypes.Error,
        });
      })
      .finally(() => {
        if (silent == false)
          timeout(() => {
            _championshipClassListState.selectedClassListIsLoading = false;
          });
      });
  };

  const refreshList = async (listId = selectedClassList.value.id) => {
    if (!championship.value) return;
    if (!listId) return;

    return await findList(championship.value.id, listId, true);
  };

  const updateCalled = async (competitionId: string, isCalled: boolean) => {
    return _championshipClassListCompetitionApi
      .updateCalled(selectedClassList.value.id, {
        id: competitionId,
        tatami: "",
        isCalled: isCalled,
      })
      .then(() => {
        return refreshList();
      })
      .catch(({ data }) => {
        add({
          title: t(
            "errors.occurs-during-updating-called-flag-of-championship-class-list-competition"
          ),
          details: data.message,
          tag: data.status,
          type: NotificationTypes.Error,
        });
      });
  };

  const updatePrepare = async (competitionId: string, isPrepare: boolean) => {
    return _championshipClassListCompetitionApi
      .updatePrepare(selectedClassList.value.id, {
        id: competitionId,
        tatami: "",
        isPrepare: isPrepare,
      })
      .then(async () => {
        await refreshList();
      })
      .catch(({ data }) => {
        add({
          title: t(
            "errors.occurs-during-updating-prepared-flag-of-championship-class-list-competition"
          ),
          details: data.message,
          tag: data.status,
          type: NotificationTypes.Error,
        });
      });
  };

  const createOrUpdateResult = async (
    listId: string,
    result: UpdateCompetitionResultBody
  ) => {
    return _championshipClassListCompetitionApi
      .createOrUpdateResult(listId, result)
      .then(() => {
        refreshList(listId);
      })
      .catch(({ data }) => {
        add({
          title: t("errors.occurs-during-modifing-result-of-competition"),
          details: data.message,
          tag: data.status,
          type: NotificationTypes.Error,
        });
      });
  };

  return {
    selectedClassListIsLoading,
    selectedClassList,
    selectedCompetition,
    remove,
    findList,
    setSelectedCompetition,
    exportListAsJSON,
    exportListsAsJSON,
    // setExecutionMode,
    // generateList,
    updateCalled,
    updatePrepare,
    createOrUpdateResult,
    exportListAsPDF,
    updateList,
  };
}
