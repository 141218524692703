import { AuthAPI } from "@/api/Auth.api";
import { Axios } from "@/api/Axios";
import { NotificationTypes } from "@/types/NotificationTypes.enum";
import { SignInBody } from "@/types/SignIn.Body";
import { SignUpBody } from "@/types/SignUp.Body";
import { computed, reactive } from "vue";
import { useI18n } from "vue-i18n";
import { AuthState } from "./Auth.state";
import { useNotificationStore } from "./Notification.store";

const _authAPI = new AuthAPI();
const state: AuthState = reactive({ isAuthenticated: false } as AuthState);

const isAuthenticated = computed(() => state.isAuthenticated);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useAuthStore() {
  const { t } = useI18n();
  const { add } = useNotificationStore();

  const signIn = async (signInData: SignInBody) => {
    return _authAPI
      .signIn(signInData)
      .then(() => {
        state.isAuthenticated = true;
        add({
          title: t("success.successfully-signed-in"),
          type: NotificationTypes.Success,
        });
      })
      .catch(({ data }) => {
        add({
          title: t("errors.occurs-during-sign-in"),
          details: data.message,
          tag: data.status,
          type: NotificationTypes.Error,
        });
      });
  };

  const signUp = async (signUpData: SignUpBody) => {
    return _authAPI
      .signUp(signUpData)
      .then(() => {
        state.isAuthenticated = false;
        add({
          title: t("success.successfully-signed-up"),
          details: t("success.successfully-signed-up-details"),
          type: NotificationTypes.Success,
        });
        return true;
      })
      .catch(({ data }) => {
        add({
          title: t("errors.occurs-during-sign-up"),
          details: data.message,
          tag: data.status,
          type: NotificationTypes.Error,
        });
      });
  };

  const signOut = async () => {
    return _authAPI
      .signOut()
      .then(() => {
        state.isAuthenticated = false;
        add({
          title: t("success.successfully-signed-out"),
          type: NotificationTypes.Success,
        });
      })
      .catch(({ data }) => {
        add({
          title: t("errors.occurs-during-sign-out"),
          details: data.message,
          tag: data.status,
          type: NotificationTypes.Error,
        });
      });
  };

  const verifyEmail = async (id: string) => {
    return _authAPI
      .verifyEmail({ id })
      .then(() => {
        add({
          title: t("success.successfully-verified-email"),
          type: NotificationTypes.Success,
        });
        return true;
      })
      .catch(({ data }) => {
        add({
          title: t("errors.occurs-during-verification"),
          details: data.message,
          tag: data.status,
          type: NotificationTypes.Error,
        });
      });
  };

  return {
    isAuthenticated,
    signIn,
    signUp,
    signOut,
    verifyEmail,
  };
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useRefreshStore() {
  const refresh = async () => {
    const refreshToken = await _authAPI.getRefreshToken();
    if ((await _authAPI.appHasRefreshToken()) && refreshToken) {
      return _authAPI
        .refreshToken(refreshToken)
        .then(() => {
          state.isAuthenticated = true;
        })
        .catch(() => {
          localStorage.removeItem(Axios.LOCAL_STORAGE_KEY);
        });
    }
    return Promise.reject();
  };

  return {
    isAuthenticated,
    refresh,
  };
}
